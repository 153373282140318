<template>
  <div id="login">
    <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules">
      <h3>露营Together后台管理系统</h3>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          prefix-icon="el-icon-user"
          placeholder="请输入用户名"
          clearable
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          prefix-icon="el-icon-lock"
          placeholder="请输入密码"
          show-password
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="rememberMe">
          记住密码
        </el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="$store.state.isLoading" @click="login">
          登 录
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { setToken } from '@/common/token'
import { login } from '@/api/main/login'

export default {
  data () {
    return {
      rememberMe: false,
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [{ required: true, message: '用户名不能为空', trigger: ['blur', 'change']}],
        password: [{ required: true, message: '密码不能为空', trigger: ['blur', 'change']}]
      }
    }
  },
  created () {
    const username = Cookies.get(window.g.PROJECT_KEY + '-Username')
    const password = Cookies.get(window.g.PROJECT_KEY + '-Password')
    const rememberMe = Cookies.get(window.g.PROJECT_KEY + '-RememberMe')
    this.loginForm.username = undefined ? '' : username
    this.loginForm.password = undefined ? '' : password
    this.rememberMe = undefined ? false : Boolean(rememberMe)
  },
  methods: {
    login () {
      this.$refs.loginFormRef.validate(valid => {
        if (valid) {
          this.$store.commit('isLoading', true)
          login(this.loginForm).then(res => {
            setToken(res)
            if (this.rememberMe) {
              Cookies.set(window.g.PROJECT_KEY + '-Username', this.loginForm.username, { expires: 30 })
              Cookies.set(window.g.PROJECT_KEY + '-Password', this.loginForm.password, { expires: 30 })
              Cookies.set(window.g.PROJECT_KEY + '-RememberMe', this.rememberMe, { expires: 30 })
            } else {
              Cookies.remove(window.g.PROJECT_KEY + '-Username')
              Cookies.remove(window.g.PROJECT_KEY + '-Password')
              Cookies.remove(window.g.PROJECT_KEY + '-RememberMe')
            }
            this.$router.push('/camp')
          })
        }
      })
    }
  }
}
</script>

<style>
#login {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/login-background.jpg");
  background-size: cover;
}
#login h3 {
  color: #707070;
  text-align: center;
  margin: 30px 0px 30px 0px;
}
#login .el-form {
  width: 350px;
  border-radius: 6px;
  background: white;
  padding: 0px 25px 0px 25px;
}
#login .el-button {
  width: 100%;
}
</style>
